import { parseJson } from '@/utils/json'

export default {
  props: {
    translations: {
      type: String,
      default: '{}',
    },
  },
  provide() {
    return {
      // $translate: (key, replacements) => this.$translate(key, replacements),
      $translate: (key, replacements) => this.$t(key, replacements),
    }
  },
  computed: {
    translationMap() {
      return parseJson(this.translations) || {}
    },
  },
  methods: {
    $translate(key, replacements = {}) {
      if (!Object.keys(this.translationMap).length) return key

      try {
        if (!Object.keys(replacements)) return this.translationMap[key].trim()
        const rawTranslation = this.translationMap[key].trim()

        return rawTranslation.replace(/{{(\w+)}}/g, (original, replaceKey) => replacements[replaceKey] || original)
      } catch (e) {
        return key
      }
    },
  },
}
