<template>
  <div class="flex-col flex justify-center dark:text-white">
    <div
      :class="{ 'mb-2': index !== features.length - 1 }"
      class="flex leading-tight"
      v-for="(feature, index) in features"
      :key="index"
    >
      <div v-if="index === 0">
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          v-bind:class="'w-4 h-4 mr-3 text-blue-600'"
          v-bind:src="'@/assets/image/icon/tick.svg'"
          v-bind:svg-inline="''"
          v-bind:role="'presentation'"
          v-bind:focusable="'false'"
        >
          <path
            fill="currentColor"
            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
          />
        </svg>
      </div>
      <div v-else-if="index === 1">
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          v-bind:class="'w-4 h-4 mr-3 text-green-600'"
          v-bind:src="'@/assets/image/icon/shield.svg'"
          v-bind:svg-inline="''"
          v-bind:role="'presentation'"
          v-bind:focusable="'false'"
        >
          <path
            fill="currentColor"
            d="M256 409.6V100l-142.9 59.5c8.4 116.2 65.2 202.6 142.9 250.1zM466.5 83.7l-192-80a48.15 48.15 0 00-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256 464C158.5 423.4 64 297.3 64 128l192-80 192 80c0 173.8-98.4 297-192 336z"
          />
        </svg>
      </div>
      <div v-else-if="index === 2">
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          v-bind:class="'w-4 h-4 mr-3 text-orange-600'"
          v-bind:src="'@/assets/image/icon/chart.svg'"
          v-bind:svg-inline="''"
          v-bind:role="'presentation'"
          v-bind:focusable="'false'"
        >
          <path
            fill="currentColor"
            d="M396.8 352h22.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-192 0h22.4c6.4 0 12.8-6.4 12.8-12.8V140.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h22.4c6.4 0 12.8-6.4 12.8-12.8V204.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zM496 400H48V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zm-387.2-48h22.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8z"
          />
        </svg>
      </div>
      <div>{{ feature }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeyFeatures',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    features() {
      return (this.value || []).slice(0, this.show)
    },
  },
}
</script>
