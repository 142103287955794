<template>
  <div class="overflow-hidden transition-all duration-200 ease-in flex flex-wrap dark:text-white" :style="{ height }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ExpansionPanel',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    height() {
      return this.value && this.mounted ? `${this.$el.scrollHeight}px` : 0
    },
  },
  mounted() {
    this.mounted = true
  },
}
</script>
