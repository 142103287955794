<template>
  <div
    :class="{ 'rounded-full overflow-hidden w-24 h-24': roundedLogo, 'w-full': !roundedLogo, 'h-full': !roundedLogo }"
    class="relative overflow-hidden bg-white border"
  >
    <NewFlag v-if="flag" />
    <LazyImage
      operation="crop"
      :alt="`${name} Logo`"
      :src="src"
      :title="name"
      placeholder="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAQAAABeK7cBAAAAC0lEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII="
      class="w-full h-full object-contain"
    />
  </div>
</template>

<script>
import LazyImage from '@/view/components/lazy-image'
import NewFlag from '@/view/components/comparison-table/new-flag'

export default {
  name: 'Logo',
  components: {
    LazyImage,
    NewFlag,
  },
  props: {
    flag: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      default: '',
    },
    roundedLogo: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
