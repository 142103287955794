<template>
  <div class="absolute top-0 right-0 overflow-hidden w-24 h-24 z-10">
    <div
      class="
        ribbon
        bg-red-600
        text-white text-xs text-center
        uppercase
        font-bold
        absolute
        transform
        pt-1
        pb-1
        w-24
        left-1/2
      "
    >
      {{ $translate('new') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewFlag',
  inject: ['$translate'],
}
</script>

<style scoped>
.ribbon {
  transform: translateY(-50%) rotate(45deg) translateY(25px);
}
</style>
