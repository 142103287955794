import { version } from '@/../package.json'

export default {
  name: 'ContextProps',
  provide() {
    return {
      $locale: () => this.locale,
      $context: () => ({
        relative: this.relative,
      }),
      $translate: this.translate,
    }
  },
  props: {
    country: {
      type: String,
      default: 'GB',
    },
    currency: {
      type: String,
      default: 'GBP',
    },
    language: {
      type: String,
      default: 'en',
    },
    relative: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      version,
    }
  },
  computed: {
    locale() {
      return {
        country: this.country,
        currency: this.currency,
        language: this.language,
      }
    },
  },
}
