<template>
  <default-layout>
    <div class="container flex-1 py-4 bg-white lg:px-10 max-w-7xl sm:py-8 lg:pt-10 lg:pb-20" v-if="offers[0]">
      <register-form modal ref="register-form"></register-form>

      <div class="prose-sm prose sm:prose prose-indigo sm:max-w-none">
        <h1>{{ $t('home.title', [offers[0].name, currentMonth, currentYear]) }}</h1>

        <div class="hidden -mt-4 md:-mt-6 sm:block"><h2>{{$t('home.title2')}}</h2></div>

        <p v-html="$t('home.description', [offers[0].name])"></p>

        <comparison-table
          class="block min-h-72 md:min-h-12"
          currency="USD"
          layout="default"
          :offers="offers"
          :dark-theme="false"
          translations="{}"
          :relative="true"
          block="main-comparison-table"
          theme="{}"
          :show-disclaimer="true"
          :action-handler="openRegisterModal"
          :redirect-handler="clickToOffer"
        ></comparison-table>

        <h2>{{$t('other-recommended-partners')}}</h2>

        <comparison-table
          class="block min-h-72 md:min-h-12"
          currency="USD"
          layout="default"
          :offers="otherOffers"
          :dark-theme="false"
          translations="{}"
          :relative="true"
          block="others-comparison-table"
          theme="{}"
          :show-disclaimer="false"
          :action-handler="openRegisterModal"
          :redirect-handler="clickToOffer"
        ></comparison-table>

        <div v-html="$t('home.description2', [offers[0].name, domain])"></div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import moment from 'moment';

import ComparisonTable from '@/view/components/comparison-table';
import RegisterForm from '@/view/components/register-form';

import DefaultLayout from "@/view/layouts/Default";

export default {
  components: {
    ComparisonTable,
    RegisterForm,
    DefaultLayout
  },
  props: {
    offers: Array,
    otherOffers: Array
  },
  data: () => ({
    showFormOnExit: true,
    exitFormCount: 0
  }),
  mounted() {
    if (this.$route.query.pp && this.$route.query.pp === 'false') {
      this.showFormOnExit = false;
    }

    if (this.showFormOnExit) {
      window.addEventListener('blur', (e) => {
        if (this.exitFormCount < 1) {
          this.openRegisterModal(e);
        }

        this.exitFormCount++;
      });
    }

    this.$gtm.trackView();
  },

  computed: {
    currentMonth() {
      return this.$t(`month.${moment().format('MMMM')}`);
    },
    currentYear() {
      return moment().format('YYYY');
    },
    domain() {
      return process.env.VUE_APP_DOMAIN || 'wealth-researchs.com';
    }
  },
  methods: {
    openRegisterModal(e) {
      e.preventDefault();

      const registerForm = this.$refs['register-form'];

      if (registerForm) {
        registerForm.openModal();
      }
    },

    clickToOffer(e, offer) {
      e.preventDefault();

      this.$gtm.trackEvent({
        event: 'click',
        offer: offer && offer.slug
      });

      let queryObject = { ...this.$route.query };

      switch (offer.slug) {
        // case 'bitcoin-bot':
        //   queryObject = {...}
        //   break;
        default:
          const { utm_source, utm_campaign } = this.$route.query;

          queryObject = {
            ...this.$route.query,
            affid: utm_source,
            affid2: utm_campaign
          };

          break;
      }

      Object.keys(queryObject).forEach(key => {
        if (queryObject[key] === undefined) {
          delete queryObject[key];
        }
      });

      const href = `${offer.href}?${new URLSearchParams(queryObject).toString()}`;
      window.location.replace(href);
    }
  }
}
</script>
