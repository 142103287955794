<template>
  <li
    class="text-sm text-gray-800 border-t border-l border-r"
    :class="{
      'bg-gray-100 dark:bg-black': position % 2 !== 1,
    }"
    :style="
      formatObjectToStyle([
        ['background-color', theme.background],
        ['color', theme.text],
      ])
    "
  >
    <div class="flex flex-wrap px-2 py-4">
      <div
        v-if="container.minSm"
        :class="{
          'w-1/12': container.sm || container.md,
          'w-1/24': container.minLg,
        }"
        class="flex items-center justify-center"
      >
        <Position :value="position" :color="theme.text" />
      </div>
      <div
        :class="{
          'w-full': container.xs,
          'w-3/12': container.sm || container.md,
          'w-4/24': container.minLg,
        }"
        class="flex items-center justify-center p-2"
      >
        <Logo :flag="offer.newFlag" :src="parsedLogo" :name="offer.name" :roundedLogo="roundedLogo" />
      </div>
      <div
        :class="{
          'w-full': container.xs,
          'w-5/12': container.sm || container.md,
          'w-7/24': container.minLg,
        }"
        class="flex items-center"
      >
        <MinDeposit :value="offer.minDeposit" />
        <StarRating :value="offer.starRating" />
      </div>
      <KeyFeatures class="px-2 w-8/24" v-if="container.minLg" :value="offer.keyFeatures" />
      <div
        :class="{
          'w-full': container.xs,
          'w-3/12': container.sm || container.md,
          'w-4/24': container.minLg,
        }"
        class="flex flex-col items-center justify-center p-2 "
      >
        <VisitButton :text="$translate('start-trading')"
          :theme="theme"
          :offer="offer"
          :href="offer.href"
          :redirect="offer.redirect"
          :action-handler="actionHandler"
          :redirect-handler="redirectHandler"
        />
        <ExpansionPanelTrigger
          :expanded="expanded"
          :text="$translate('more-info')"
          class="mt-1"
          @click="expanded = !expanded"
        />
      </div>
    </div>
    <ExpansionPanel :value="expanded">
      <template>
        <TitleAndContent
          :class="{
            'w-full': container.xs,
            'w-6/12 border-r-2': container.sm || container.md || (container.minLg && hideRegulationsList),
            'w-4/12 border-r-2': container.minLg && !hideRegulationsList,
          }"
          :title="$translate('description')"
          :content="offer.description"
        />
        <TitleAndContent
          :class="{
            'w-full': container.xs,
            'w-6/12': container.sm || container.md || (container.minLg && hideRegulationsList),
            'w-4/12 border-r-2': container.minLg && !hideRegulationsList,
          }"
          :title="$translate('payment-methods')"
        >
          {{ (offer.paymentMethods || []).join(', ') }}
        </TitleAndContent>
        <TitleAndContent
          v-if="!hideRegulationsList"
          :class="{
            'w-full': container.xs,
            'w-6/12 border-r-2': container.sm || container.md,
            'w-4/12': container.minLg,
          }"
          :title="$translate('full-regulations-list')"
        >
          {{ (offer.regulations || []).join(', ') }}
        </TitleAndContent>
      </template>
    </ExpansionPanel>
    <Disclaimer v-if="offer.disclaimer && showDisclaimer" :value="offer.disclaimer" :color="theme.text" />
  </li>
</template>

<script>
// Components
import ExpansionPanel from '@/view/components/expansion-panel'
import ExpansionPanelTrigger from '@/view/components/expansion-panel-trigger'
import KeyFeatures from '@/view/components/comparison-table/key-features'
import MinDeposit from '@/view/components/comparison-table/min-deposit'
import Disclaimer from '@/view/components/comparison-table/disclaimer'
import Logo from '@/view/components/comparison-table/logo'
import Position from '@/view/components/comparison-table/position'
import StarRating from '@/view/components/comparison-table/star-rating'
import TitleAndContent from '@/view/components/comparison-table/title-and-content'
import VisitButton from '@/view/components/visit-button'

// Utils
import { formatObjectToStyle } from '@/utils/style'

export default {
  name: 'Default',
  components: {
    Disclaimer,
    ExpansionPanel,
    ExpansionPanelTrigger,
    KeyFeatures,
    Logo,
    MinDeposit,
    Position,
    StarRating,
    TitleAndContent,
    VisitButton,
  },
  inject: ['$container', '$translate'],
  provide() {
    return {
      $offer: () => this.offer,
      $table: () => ({
        position: this.position,
        totalPositions: this.total,
      })
    }
  },
  props: {
    position: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
    roundedLogo: {
      type: Boolean,
      default: false,
    },
    hideRegulationsList: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: Object,
      default: () => ({}),
    },
    showDisclaimer: {
      type: Boolean,
      default: false
    },
    actionHandler: {
      type: Function
    },
    redirectHandler: {
      type: Function
    }
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    container() {
      return this.$container()
    },
    parsedLogo() {
      return this.roundedLogo ? this.offer.logoThumbnail : this.offer.logoFeatured
    }
  },
  methods: {
    formatObjectToStyle,
  },
}
</script>
