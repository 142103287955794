<template>
  <div :class="{ dark: darkTheme }">
    <ol class="font-sans antialiased border-b dark:bg-black" v-if="shouldShowTable">
      <component
        :is="componentName"
        v-for="(offer, index) in offers"
        :key="offer.slug"
        :offer="offer"
        :position="index + 1"
        :total="offers.length"
        :roundedLogo="roundedLogo"
        :hide-regulations-list="hideRegulationsList"
        :theme="parsedTheme"
        :show-disclaimer="showDisclaimer"
        :action-handler="actionHandler"
        :redirect-handler="redirectHandler"
      />
    </ol>
  </div>
</template>

<script>
// Helpers
require('@ungap/url-search-params')
import { parseJson } from '@/utils/json'
// Components
import Default from '@/view/components/comparison-table/layouts/default'

// Mixins
import handleResize from '@/mixins/handle-resize'
import handleTranslations from '@/mixins/handle-translations'
import handleContext from '@/mixins/handle-context'

export default {
  name: 'ComparisonTable',
  provide() {
    return {
      $component: 'filtered-table-horizontal',
      $block: this.block || 'comparison-table'
    }
  },
  components: {
    Default
  },
  mixins: [handleContext, handleResize, handleTranslations],
  props: {
    ...handleContext.props,
    ...handleResize.props,
    ...handleTranslations.props,
    layout: {
      type: String,
      default: 'default',
      validation: layout => ['default'].includes(layout),
    },
    offers: {
      type: Array,
      default: [],
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
    roundedLogo: {
      type: Boolean,
      default: false,
    },
    hideRegulationsList: {
      type: Boolean,
      default: false,
    },
    block: {
      type: String,
    },
    theme: {
      type: String,
    },
    showDisclaimer: {
      type: Boolean,
    },
    actionHandler: {
      type: Function
    },
    redirectHandler: {
      type: Function
    }
  },
  computed: {
    componentName() {
      return this.layout.charAt(0).toUpperCase() + this.layout.slice(1)
    },
    parsedTheme() {
      return parseJson(this.theme) || {}
    },
    shouldShowTable() {
      return this.offers.length
    },
  },
}
</script>
