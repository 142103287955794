<template>
  <div
    :aria-expanded="expanded"
    :aria-controls="expansionPanelId"
    class="flex items-center justify-center w-full mt-2 cursor-pointer outline-none dark:text-white"
    role="button"
    tabindex="0"
    @click="handleClick"
    @keydown.enter="handleClick"
  >
    <span class="mr-2 text-xs">{{ text }}</span>
    <svg
      aria-hidden="true"
      data-prefix="fal"
      data-icon="chevron-down"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      v-bind:src="'@/assets/image/icon/chevron-down.svg'"
      v-bind:svg-inline="''"
      :class="['inline-block w-2 h-2 transform duration-200', { 'transform rotate-180': expanded }]"
      v-bind:role="'presentation'"
      v-bind:focusable="'false'"
    >
      <path
        fill="currentColor"
        d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'ExpansionPanelTrigger',
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    expansionPanelId: {
      type: String,
    },
    text: {
      type: String,
      default: 'more info',
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
</script>
