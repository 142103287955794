<template>
  <div class="w-1/2 flex flex-col items-center leading-6 dark:text-white">
    <div>{{ top }}</div>
    <div class="text-2xl font-bold leading-9 h-9">
      <slot>{{ middle }}</slot>
    </div>
    <slot name="bottom">
      <div v-if="bottom" class="text-center">{{ bottom }}</div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'LabelledStatistic',
  props: {
    top: {
      type: String,
      required: true,
    },
    middle: {
      type: [Number, String],
    },
    bottom: {
      type: String,
    },
  },
}
</script>
