export default {
  name: 'handleResize',
  provide() {
    return {
      $container: () => this.container,
    }
  },
  data: () => ({
    containerWidth: 'lg',
  }),
  computed: {
    container() {
      return {
        width: this.containerWidth,
        minSm: ['xl', 'lg', 'md', 'sm'].includes(this.containerWidth),
        minMd: ['xl', 'lg', 'md'].includes(this.containerWidth),
        minLg: ['xl', 'lg'].includes(this.containerWidth),
        maxSm: !['xl', 'lg', 'md'].includes(this.containerWidth),
        maxMd: !['xl', 'lg'].includes(this.containerWidth),
        xs: this.containerWidth === 'xs',
        sm: this.containerWidth === 'sm',
        md: this.containerWidth === 'md',
        lg: this.containerWidth === 'lg',
        xl: this.containerWidth === 'xl',
      }
    },
  },
  mounted() {
    this.containerWidth = this.getSizeForWidth();
    window.addEventListener('resize', this.onResize, true);
  },
  methods: {
    getSizeForWidth() {
      const width = this.$el.offsetWidth;
  
      if (width < 640) return 'xs';
      if (width >= 640 && width < 768) return 'sm';
      if (width >= 768 && width < 1024) return 'md';
      if (width >= 1024 && width < 1280) return 'lg';

      return 'xl';
    },
    onResize() {
      this.containerWidth = this.getSizeForWidth()
    }
  }
}
