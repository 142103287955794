<template>
  <div>
    <home-start-page v-if="showStartPage"></home-start-page>
    <home-offer v-else :offers="offers" :otherOffers="otherOffers"></home-offer>
  </div>
</template>

<script>
import HomeOffer from '@/view/pages/home/components/HomeOffer';
import HomeStartPage from '@/view/pages/home/components/HomeStartPage';

export default {
  components: {
    HomeOffer,
    HomeStartPage
  },
  data: () => ({
    showStartPage: true,
    offers: [],
    otherOffers: []
  }),
  created() {
    if (this.$route.query.adgroupname) {
      const value = this.$route.query.adgroupname.toLowerCase().split('-');

      if (value.length < 3) return;

      const country = value[0];
      const language = value[1];
      const offerName = value[2];

      const validLanguage = Object.keys(this.$i18n.messages).includes(language);

      if (!validLanguage) return;

      if (this.$i18n.locale !== language && validLanguage) {
        this.$i18n.locale = language;
      }

      const offersObj = this.$i18n.t('offers');
      const offers = Object.keys(offersObj).map(it => ({ slug: it, key: it, ...offersObj[it] }));
      const offerIndex = offers.findIndex(it => it.name.replaceAll(' ', '').toLowerCase() === offerName);

      if (offerIndex == -1) return;

      this.offers = [offers[offerIndex]];

      offers.splice(offerIndex, 1)

      this.otherOffers = offers.slice(0, 3);

      this.showStartPage = false;
    }
  }
}
</script>
