<template>
  <div
    class="text-3xl font-bold dark:text-white"
    :class="{
      'text-yellow-600': value === 1,
      'text-gray-600': value > 1,
    }"
    :style="color && 'color: ' + color"
  >
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'Position',
  props: {
    value: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
  },
}
</script>
