export const formatPercentage = (num, decimals = 2) => {
  return num && !isNaN(num) ? Number(num).toFixed(decimals) + '%' : null
}

export const formatNumber = (
  num,
  { country = 'GB', currency = 'GBP', language = 'en' } = {},
  {
    compactDisplay = 'short',
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
    notation = 'standard',
    style = 'decimal',
  } = {}
) => {
  if (num === undefined || num === null) return num

  const locale = [country.toUpperCase(), language.toLowerCase()].join('-')
  const options = {
    compactDisplay,
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits,
    maximumFractionDigits,
    notation,
    style,
  }

  try {
    return new Intl.NumberFormat(locale, options).format(num)
  } catch (e) {
    // 'narrow-symbol' is not supported by Safari, fallback to 'symbol' if needed.
    options.currencyDisplay = 'symbol'
    return new Intl.NumberFormat(locale, options).format(num)
  }
}

export const sign = value => {
  return Math.sign(value)
}
