<template>
  <img :alt="alt" :src="placeholder" :title="title" />
</template>

<script>
export default {
  name: 'LazyImage',
  props: {
    alt: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    },
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: undefined,
    },
    optimize: {
      type: [String, Boolean],
      default: true,
    },
  },
  computed: {
    optimizeBoolean() {
      return this.optimize === true || this.optimize === 'true'
    },
  },
  watch: {
    src: {
      handler() {
        this.$nextTick(() => this.setUpObserver())
      },
      immediate: true,
    },
  },
  methods: {
    setUpObserver() {
      const config = {
        rootMargin: '500px 0px',
        threshold: 0.01,
      }

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            observer.unobserve(entry.target)
            this.handleImage(entry.target)
          }
        })
      }, config)

      observer.observe(this.$el)
    },
    preloadImage(url) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.load = resolve(url)
        image.error = reject
        image.src = url
      })
    },
    async handleImage() {
      if (!this.src) return

      this.$el.src = this.src;
    },
  },
}
</script>
