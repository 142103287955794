var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"text-sm text-gray-800 border-t border-l border-r",class:{
    'bg-gray-100 dark:bg-black': _vm.position % 2 !== 1,
  },style:(_vm.formatObjectToStyle([
      ['background-color', _vm.theme.background],
      ['color', _vm.theme.text],
    ]))},[_c('div',{staticClass:"flex flex-wrap px-2 py-4"},[(_vm.container.minSm)?_c('div',{staticClass:"flex items-center justify-center",class:{
        'w-1/12': _vm.container.sm || _vm.container.md,
        'w-1/24': _vm.container.minLg,
      }},[_c('Position',{attrs:{"value":_vm.position,"color":_vm.theme.text}})],1):_vm._e(),_c('div',{staticClass:"flex items-center justify-center p-2",class:{
        'w-full': _vm.container.xs,
        'w-3/12': _vm.container.sm || _vm.container.md,
        'w-4/24': _vm.container.minLg,
      }},[_c('Logo',{attrs:{"flag":_vm.offer.newFlag,"src":_vm.parsedLogo,"name":_vm.offer.name,"roundedLogo":_vm.roundedLogo}})],1),_c('div',{staticClass:"flex items-center",class:{
        'w-full': _vm.container.xs,
        'w-5/12': _vm.container.sm || _vm.container.md,
        'w-7/24': _vm.container.minLg,
      }},[_c('MinDeposit',{attrs:{"value":_vm.offer.minDeposit}}),_c('StarRating',{attrs:{"value":_vm.offer.starRating}})],1),(_vm.container.minLg)?_c('KeyFeatures',{staticClass:"px-2 w-8/24",attrs:{"value":_vm.offer.keyFeatures}}):_vm._e(),_c('div',{staticClass:"flex flex-col items-center justify-center p-2",class:{
        'w-full': _vm.container.xs,
        'w-3/12': _vm.container.sm || _vm.container.md,
        'w-4/24': _vm.container.minLg,
      }},[_c('VisitButton',{attrs:{"text":_vm.$translate('start-trading'),"theme":_vm.theme,"offer":_vm.offer,"href":_vm.offer.href,"redirect":_vm.offer.redirect,"action-handler":_vm.actionHandler,"redirect-handler":_vm.redirectHandler}}),_c('ExpansionPanelTrigger',{staticClass:"mt-1",attrs:{"expanded":_vm.expanded,"text":_vm.$translate('more-info')},on:{"click":function($event){_vm.expanded = !_vm.expanded}}})],1)],1),_c('ExpansionPanel',{attrs:{"value":_vm.expanded}},[[_c('TitleAndContent',{class:{
          'w-full': _vm.container.xs,
          'w-6/12 border-r-2': _vm.container.sm || _vm.container.md || (_vm.container.minLg && _vm.hideRegulationsList),
          'w-4/12 border-r-2': _vm.container.minLg && !_vm.hideRegulationsList,
        },attrs:{"title":_vm.$translate('description'),"content":_vm.offer.description}}),_c('TitleAndContent',{class:{
          'w-full': _vm.container.xs,
          'w-6/12': _vm.container.sm || _vm.container.md || (_vm.container.minLg && _vm.hideRegulationsList),
          'w-4/12 border-r-2': _vm.container.minLg && !_vm.hideRegulationsList,
        },attrs:{"title":_vm.$translate('payment-methods')}},[_vm._v(" "+_vm._s((_vm.offer.paymentMethods || []).join(', '))+" ")]),(!_vm.hideRegulationsList)?_c('TitleAndContent',{class:{
          'w-full': _vm.container.xs,
          'w-6/12 border-r-2': _vm.container.sm || _vm.container.md,
          'w-4/12': _vm.container.minLg,
        },attrs:{"title":_vm.$translate('full-regulations-list')}},[_vm._v(" "+_vm._s((_vm.offer.regulations || []).join(', '))+" ")]):_vm._e()]],2),(_vm.offer.disclaimer && _vm.showDisclaimer)?_c('Disclaimer',{attrs:{"value":_vm.offer.disclaimer,"color":_vm.theme.text}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }