<template>
  <div class="p-2 border-t-2 border-gray-200 leading-relaxed">
    <div class="font-bold pb-4">{{ title }}</div>
    <slot>{{ content }}</slot>
  </div>
</template>

<script>
export default {
  name: 'TitleAndContent',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      default: undefined,
    },
  },
}
</script>
