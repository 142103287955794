<template>
  <a
    :href="href"
    rel="noopener noreferrer nofollow"
    :type="type"
    @click="handler($event, offer)"
    :class="{
      'bg-yellow-300 hover:bg-yellow-400 text-black': color === 'yellow',
      'bg-carrot-light hover:bg-carrot text-white': color === 'carrot',
    }"
    :style="
      formatObjectToStyle([
        ['background-color', theme.buttonBackground],
        ['color', theme.buttonText],
      ])
    "
    class="
      relative
      flex
      items-center
      justify-center
      w-full
      px-3
      py-3
      overflow-hidden
      text-lg text-center
      transition-colors
      duration-150
      rounded-md
      bg-gradient-tb
    "
  >
    <span class="relative z-10 mr-1 font-semibold leading-7 pointer-events-none">{{ text }}</span>
    <div class="z-10 transition-transform duration-150 transform pointer-events-none arrow">
      <svg
        aria-hidden="true"
        data-prefix="far"
        data-icon="long-arrow-right"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        v-bind:class="'w-3 h-3 pointer-events-none'"
        v-bind:src="'@/assets/image/icon/arrow-right.svg'"
        v-bind:svg-inline="''"
        v-bind:role="'presentation'"
        v-bind:focusable="'false'"
      >
        <path
          fill="currentColor"
          d="M295.515 115.716l-19.626 19.626c-4.753 4.753-4.675 12.484.173 17.14L356.78 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h344.78l-80.717 77.518c-4.849 4.656-4.927 12.387-.173 17.14l19.626 19.626c4.686 4.686 12.284 4.686 16.971 0l131.799-131.799c4.686-4.686 4.686-12.284 0-16.971L312.485 115.716c-4.686-4.686-12.284-4.686-16.97 0z"
        />
      </svg>
    </div>
    <span
      :class="{
        'bg-yellow-400': color === 'yellow',
        'bg-carrot': color === 'carrot',
      }"
      class="absolute bottom-0 w-full pointer-events-none h-2/4"
      :style="theme.buttonBackground && 'background-color: ' + theme.buttonBackground"
    ></span>
  </a>
</template>

<script>
import { formatObjectToStyle } from '@/utils/style'

export default {
  name: 'VisitButton',
  props: {
    offer: {
      type: Object,
      default: {}
    },
    href: {
      type: String,
      default: '/'
    },
    text: {
      type: String,
      default: 'Start trading',
    },
    color: {
      type: String,
      default: 'yellow',
      validator(color) {
        return ['yellow', 'carrot'].includes(color)
      },
    },
    type: {
      type: String,
    },
    theme: {
      type: Object,
    },
    actionHandler: {
      type: Function
    },
    redirectHandler: {
      type: Function
    },
    redirect: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    handler() {
      if (!this.redirect && this.actionHandler) return this.actionHandler;
      if (this.redirect && this.redirectHandler) return this.redirectHandler;

      return this.defaultHandler;
    }
  },
  methods: {
    formatObjectToStyle,

    defaultHandler() {}
  },
}
</script>

<style lang="scss" scoped>
a {
  &:hover {
    .arrow {
      @apply translate-x-2;
    }
  }
}
</style>
