<template>
  <LabelledStatistic :top="$translate('min-deposit')" :middle="formatCurrency(value, locale, currencyOptions)" />
</template>

<script>
// Components
import LabelledStatistic from '@/view/components/comparison-table/labelled-statistic'
// Helpers
import { formatCurrency } from '@/utils/currency'

export default {
  name: 'MinDeposit',
  inject: ['$locale', '$translate'],
  components: {
    LabelledStatistic,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    currencyOptions: {
      maximumFractionDigits: 0,
    },
  }),
  methods: {
    formatCurrency,
  },
  computed: {
    locale() {
      return this.$locale()
    },
  },
}
</script>
