import { formatNumber } from '@/utils/number'

export function formatCurrency(
  value = 0,
  { country = 'GB', currency = 'GBP', language = 'en' } = {},
  { minimumFractionDigits = 0, maximumFractionDigits = 2, notation = 'standard', compactDisplay = 'short' } = {}
) {
  const formatted = formatNumber(
    value,
    { country, currency, language },
    {
      compactDisplay,
      currency,
      minimumFractionDigits,
      maximumFractionDigits,
      notation,
      style: 'currency',
    }
  )

  if (!value) return '-'
  return formatted
}

export const getCurrencySymbol = (currency = 'USD', language = 'en', country = 'US') => {
  return new Intl.NumberFormat(`${language}-${country}`, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  })
    .formatToParts(0)
    .find(part => part.type === 'currency').value
}

export default {
  formatCurrency,
}
